import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../index.css';
import { API_URL, Loader } from "../App";
import Swal from "sweetalert2";
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode'; // Keeping this as per your preference
import { useNavigate } from "react-router-dom";
import IsLogin from "../user/IsLogin";

export default function Register({ setIsLogin }) {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [state, setState] = useState("");
    const [academic, setAcademic] = useState("");

    const validateForm = () => {
        if (!name) {
            showAlert('warning', 'Name is required!');
            return false;
        }
        if (!mobile || !/^\d{10}$/.test(mobile)) {
            showAlert('warning', 'Please enter a valid 10-digit mobile number!');
            return false;
        }
        if (!state) {
            showAlert('warning', 'State is required!');
            return false;
        }
        if (!email || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
            showAlert('warning', 'Please enter a valid email address!');
            return false;
        }
        if (!password || password.length < 6) {
            showAlert('warning', 'Password must be at least 6 characters long!');
            return false;
        }
        if (!academic) {
            showAlert('warning', 'Please select your current academic status!');
            return false;
        }
        return true;
    };

    const Register = () => {
        if (!validateForm()) {
            return;
        }
    
        // Prepare the form data
        const formData = new FormData();
        formData.append('action', 'register');
        formData.append('name', name);
        formData.append('mobile', mobile);
        formData.append('state', state);
        formData.append('email', email);
        formData.append('password', password);
        formData.append('academic', academic);
    
        // Send a POST request to the API with the FormData
        fetch(`${API_URL}/user/Auth.php`, {
            method: 'POST',
            body: formData,
        })
        .then(response => {return response.json()}) // Convert the response to JSON
        .then(data => {
            console.log(data.status); // Debug the entire response object
            if (data.status == true) {
                setTimeout(() => {
                    showAlert('success', 'Registration successful! A Verification Email is sent.');
                }, 2000);
                navigate('/login');
            } else {
                showAlert('error', data.message || 'Registration failed!');
            }
        })
        .catch(error => {
            console.error('Error during registration:', error);
            showAlert('error', 'An error occurred during registration.');
        })
        .finally(() => {
            setIsLoading(false);
        });
    };
    

    const responseMessage = (response) => {
        try {
            const decoded = jwtDecode(response.credential);
            const userUID = decoded.sub;
            localStorage.setItem('userUID', userUID);
            navigate('/home');
            setIsLogin(true);
        } catch (error) {
            console.error('Error decoding JWT token', error);
        }
    };

    const errorMessage = (error) => {
        console.log(error);
    };

    const showAlert = (icon, text) => {
        Swal.fire({
            text: text,
            icon: icon,
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
            showCancelButton: false
        });
    };

    return (
        <div>
            <div className="authcontainer bg-dark container justify-content-center align-items-center">
                <IsLogin />
                <Loader loading={isLoading} />
                <div className="mb-4"></div>
                <div className='fs-5 text-center mb-1 text-white'>Create Account!</div>
                <div className='fs-6 text-center mb-4 text-white'>
                    Prepare for your exams by chapter-wise premium mock tests. <br /> Powered by mcqkaro.com.
                </div>
            </div>

            <div className="auth container card p-4">
                <input value={name} onChange={(e) => setName(e.target.value)} className="form-control mb-2" placeholder="Name" type="text"/>
                <input value={mobile} onChange={(e) => setMobile(e.target.value)} className="form-control mb-2" placeholder="Mobile Number" type="number"/>
                <input value={state} onChange={(e) => setState(e.target.value)} className="form-control mb-2" placeholder="State" type="text"/>
                <input value={email} onChange={(e) => setEmail(e.target.value)} className="form-control mb-2" placeholder="Email" type="text"/>
                <input value={password} onChange={(e) => setPassword(e.target.value)} className="form-control mb-2" placeholder="Password" type="password"/>

                <select className='form-select mb-3' value={academic} onChange={(e) => setAcademic(e.target.value)}>
                    <option value="">Current Academic Status</option>
                    <option value="Class 9">Class 9</option>
                    <option value="Class 10">Class 10</option>
                    <option value="Class 12 Commerce">Class 12 Commerce</option>
                    <option value="Class 12 Non Medical">Class 12 Non Medical</option>
                    <option value="Class 12 Medical">Class 12 Medical</option>
                    <option value="Class 12 Humanities">Class 12 Humanities</option>
                    <option value="College Student">College Student</option>
                    <option value="Graduated">Graduated</option>
                    <option value="Working Professional">Working Professional</option>
                </select>
                <button onClick={Register} className="btn btn-primary mb-2">Continue</button>
                <p className="text-center">or</p>
                <div className="mb-1"></div>
                <button
                    onClick={() => {
                        navigate('/login');
                    }}
                    className="btn btn-outline-success text-center p-2">Login
                </button>
            </div>
            <div id="recaptcha-container"></div>
            <div className='text-center mt-5' style={{ color: "black", opacity: "0.5", position: "relative", bottom: 15, left: 0, right: 0 }}>By continuing, you agree to our Terms & Condition and Privacy Policy.</div>
        </div>
    );
}
