import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { useNavigate } from "react-router-dom";
import AdminHeader from "./adminHeader";
import Swal from "sweetalert2";
import { API_URL, Loader } from "../App";
import { useParams } from 'react-router-dom';

export default function ManageStreams() {
    const [isLoading, setLoading] = useState(false);
    const [Streamid, setStreamid] = useState("");
    const [StreamName, setStreamName] = useState("");
    const [StreamImage, setStreamImage] = useState("");
    const [StreamList, setStreamList] = useState([]);
    const [BoardList, setBoardList] = useState([]);
    const [selectedBoard, setselectedBoard] = useState("");

    const [addForm, setaddForm] = useState(true);

    const { id } = useParams();


    useEffect(() => {
        const data = new FormData();
        data.append("action", "fetch_all_boards");
        setLoading(true);
        fetch(API_URL + '/admin/Categories.php', {
            method: "POST",
            body: data
        })
            .then(res => { return res.json() })
            .then(data => {
                if (data.status == true) {
                    setBoardList(data.data);
                } else {
                    showAlert("warning", "No stream found, kindly add a board firts.")
                }
            })
    }, [])

    const showAlert = (icon, text) => {
        Swal.fire({
            text: text,
            icon: icon,
            timer: 2000, // 2 seconds
            timerProgressBar: true,
            showConfirmButton: false,
            showCancelButton: false
        }).then((result) => {
            // This code will run when the timer expires
            if (result.dismiss === Swal.DismissReason.timer) {
            }
        });
    };

    useEffect(() => {

        setLoading(true);
        const data = new FormData();
        data.append("action", "fetch_streams");

        fetch(`${API_URL}/admin/Categories.php`, {
            method: "POST",
            body: data
        })

            .then((response) => response.json())
            .then((data) => {
                if (data.status === true) {
                    setStreamList(data.data);
                } else {
                    console.error('Error fetching streams:', data.message); // Log or handle the error message
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching streams:', error); // Log the fetch error
                setLoading(false);
            });
    }, [id]); // Include id in the dependency array

    function addStream() {

        if(selectedBoard == 0) {
            showAlert("warning", "Please select a board first");
            return null;
        }

        setLoading(true);
        const data = new FormData();
        data.append("action", "add_category_two")
        data.append("name", StreamName);
        data.append("image", StreamImage);
        data.append("boardID", selectedBoard);

        fetch(API_URL + "/admin/Categories.php", {
            method: 'POST',
            body: data
        })
            .then(function (res) { return res.json(); })
            .then(function (data) {
                if (data.status === true) {
                    showAlert("success", data.message);
                    window.location.reload();
                } else {
                    showAlert("warning", data.message);
                }
                setLoading(false);
            })
            .catch(function (err) {
                setLoading(false);
                showAlert("warning", "Add all details properly or Stream already exists.");
            });
    }

    function editStream() {
        setLoading(true);
        const data = new FormData();
        data.append("action", "edit_category_two")
        data.append("id", Streamid);
        data.append("name", StreamName);
        data.append("image", StreamImage);
        data.append("boardID", selectedBoard)

        fetch(API_URL + "/admin/Categories.php", {
            method: 'POST',
            body: data
        })
            .then(function (res) { return res.json(); })
            .then(function (data) {
                if (data.status === true) {
                    showAlert("success", "Stream edited successfully");
                    window.location.reload();
                } else {
                    showAlert("warning", "Add all details properly.");
                }
                setLoading(false);
            })
            .catch(function (err) {
                setLoading(false);
                showAlert("warning", "Add all details properly.");
            });
    }

    function deleteStream(id) {
        setLoading(true);
        const data = new FormData();
        data.append("action", "delete_category_two");
        data.append("id", id);

        fetch(API_URL + "/admin/Categories.php", {
            method: 'POST',
            body: data
        })
            .then(function (res) { return res.json(); })
            .then(function (data) {
                if (data.status === true) {
                    showAlert("success", "Stream deleted successfully");
                    window.location.reload();
                } else {
                    showAlert("warning", "Add all details properly.");
                }
                setLoading(false);
            })
            .catch(function (err) {
                setLoading(false);
                showAlert("warning", "Add all details properly.");
            });
    }

    return (
        <div style={{ marginBottom: 150 }}>
            <Loader loading={isLoading} />
            <AdminHeader />
            <div className="container ">
                {addForm == true ?
                    <div className="card p-3" >
                        <p className="fs-5 text-center">Add Stream</p>
                        <input value={StreamName} onChange={(e) => {
                            setStreamName(e.target.value);
                        }} className="form-control mb-2" placeholder="Stream Name" />
                        <input value={StreamImage} onChange={(e) => {
                            setStreamImage(e.target.value);
                        }} className="form-control mb-2" placeholder="Image Link" />
                        <select class="form-select mb-2" onClick={(e) => {setselectedBoard(e.target.value)}}>
                            <option value={0}>Select Board</option>
                            {BoardList.map((board) => {
                                return <option key={board.id} value={board.id}>{board.name}</option>;
                            })}
                        </select>
                        <button onClick={addStream} className="btn btn-primary w-100">Save</button>
                    </div>
                    : <div className="card p-3" >
                        <p className="fs-5 text-center">Edit Stream</p>
                        <input value={StreamName} onChange={(e) => {
                            setStreamName(e.target.value);
                        }} className="form-control mb-2" placeholder="Stream Name" />
                        <input value={StreamImage} onChange={(e) => {
                            setStreamImage(e.target.value);
                        }} className="form-control mb-2" placeholder="Image Link" />
                        <select class="form-select mb-2" onClick={(e) => {setselectedBoard(e.target.value)}}>
                            <option value={0}>Select Board</option>
                            {BoardList.map((board) => {
                                return <option key={board.id} value={board.id}>{board.name}</option>;
                            })}
                        </select>
                        <button onClick={editStream} className="btn btn-primary w-100 mb-2">Save</button>
                        
                        <button onClick={() => {
                            setStreamid("");
                            setStreamImage("");
                            setStreamName("")
                            setaddForm(true)
                        }} className="btn btn-danger w-100">Close</button>
                    </div>
                }

                <div className='mt-3'>
                    <div className="table-responsive rounded rounded-3">
                        <table className='table table-striped table-bordered'>
                            <thead>
                                <tr>
                                    <th>Stream Name</th>
                                    <th>Board</th>
                                    <th>Image Link</th>
                                    <th className="text-end">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {StreamList.map((Stream, index) => (
                                    <tr key={index}>
                                        <td>{Stream.name}</td>
                                        <td>{Stream.board_name}</td>
                                        <td>{Stream.image}</td>
                                        <td className='m-0 p-2' style={{ width: "30%" }}>
                                            <div className='d-flex justify-content-end gap-2'>
                                            <button className='btn btn-success' onClick={() => {
                                                    window.location.href = "/admin/subjects/" + Stream.id;
                                                    localStorage.setItem("stream", Stream.name)
                                                }}>Add Subject</button>
                                                <button className='btn btn-warning' onClick={() => {
                                                    setStreamid(Stream.id);
                                                    setStreamName(Stream.name);
                                                    setStreamImage(Stream.image);
                                                    setaddForm(false)
                                                }}>Edit</button>
                                                <button onClick={() => {
                                                    deleteStream(Stream.id)
                                                }} className='btn btn-danger'>Delete</button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
