import React, { useEffect, useState, useCallback } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { useNavigate } from 'react-router-dom';
import Header from "./Header.jsx";
import { API_URL, Loader } from "../App.js";
import { useParams } from 'react-router-dom';
import Swal from "sweetalert2";
import useRazorpay from "react-razorpay";

export default function Subjects() {

    const { id } = useParams();
    const [Razorpay, isLoaded] = useRazorpay();
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [amount, setAmount] = useState(0);

    function move(id) {
        navigate(`/mocks/${id}`);
    }

    const showAlert = (icon, text) => {
        Swal.fire({
            text: text,
            icon: icon,
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
        });
    };

    useEffect(() => {
        setIsLoading(true);
        const data = new FormData();
        data.append("action", "fetch_subjects_by_stream");
        data.append("streamID", id);
        data.append("userID", localStorage.getItem("userUID"));
        fetch(API_URL + '/user/Categories.php', {
            method: "POST",
            body: data
        })
            .then(res => res.json())
            .then(data => {
                if (data.status === true) {
                    setCategories(data.data);
                } else {
                    setCategories([]);
                }
                setIsLoading(false);
            });
    }, [id]);

    function handlePaymentResponse(response, subjectID) {
        const additionalData = {
            uid: localStorage.getItem("userUID"),
            subjectID: subjectID
        };

        const payload = {
            ...additionalData,
            order_id: response.razorpay_order_id,
            payment_id: response.razorpay_payment_id,
            signature: response.razorpay_signature
        };

        fetch(API_URL + "/user/razorpay/verify-signature.php", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(res => res.json())
            .then(data => {
                if (data.status) {
                    showAlert("success", "Payment Successful, Reloading.");
                    setTimeout(() => {
                        window.location.reload()
                    }, 2000);
                } else {
                    showAlert("warning", "Payment Failed, retry again later or choose another payment option.");
                }
            })
            .catch(() => {
                showAlert("error", "Internal Server Error: Unauthentic Signature.");
            });
    }

    const handlePayment = useCallback(async (event, categoryPrice, subjectID) => {
        event.stopPropagation(); // Prevent click event from propagating to parent elements
        console.log(amount)
        setIsLoading(true);

        fetch(API_URL + `/user/razorpay/create-order.php?amount=${categoryPrice}&uid=${localStorage.getItem('userUID')}`, {
            method: 'GET',
        })
            .then(res => res.json())
            .then(data => {
                setIsLoading(false);
                const options = {
                    key: "rzp_live_i8V16zEA5Vd7ZB",
                    amount: data.amount,
                    currency: "INR",
                    name: "MCQKaro",
                    description: "Payment to MCQCKaro.",
                    image: "https://mcqkaro.com/wp-content/uploads/2023/09/Untitled-design-5-1024x247.png",
                    order_id: data.order_id,
                    handler: (response) => handlePaymentResponse(response, subjectID), // Pass subjectID here
                    prefill: {},
                    notes: {
                        address: "MCQKaro",
                    },
                    theme: {
                        color: "#3399cc",
                    },
                };

                const rzpay = new Razorpay(options);
                rzpay.open();
            });
    }, [Razorpay]);

    return (
        <div>
            <Header />
            <Loader loading={isLoading} />
            <div className="container">
                <h5 className="text-center mb-3">Your Subject</h5>
                {categories.length > 0 ? (
                    <div className="d-flex flex-wrap" style={{ justifyItems: 'space-between', gap: '10px' }}>
                        {categories.map((category) => (
                            <div onClick={() => move(category.id)} key={category.id}
                                className="d-flex gap-2 card mb-1 rounded-5 p-3 justify-content-center align-items-center"
                                style={{ width: "48.5%" }}>
                                <img src={category.image} alt="image"
                                    style={{ maxHeight: "100px" }}
                                />
                                <p className="fs-5 mb-0">{category.name}</p>
                                {category.is_purchased === true ? (
                                    <button className='mb-0 rounded-5 w-100 btn btn-outline-dark' onClick={(e) => e.stopPropagation()}>
                                        Unlocked
                                    </button>
                                ) : (
                                    <button onClick={(e) => handlePayment(e, category.price, category.id)} className='mb-0 rounded-5 w-100 btn btn-outline-dark'>
                                        Unlock at ₹{category.price}
                                    </button>
                                )}
                                <button onClick={(e) => move(category.id)} className='mb-0 rounded-5 w-100 btn btn-dark'>
                                        View Mocks
                                    </button>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p className="text-center mt-3 fs-3">Coming Soon</p>
                )}
            </div>
        </div>
    );
}
