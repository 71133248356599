import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { useNavigate, useParams } from 'react-router-dom';
import { API_URL, Loader } from "../App.js";
import Header from './Header.jsx';
import Swal from "sweetalert2";

export default function Quiz() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [answers, setAnswers] = useState({});
    const [questions, setQuestions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const showAlert = (icon, text) => {
        Swal.fire({
            text: text,
            icon: icon,
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
        });
    };

    useEffect(() => {
        setIsLoading(true);

        const data = new FormData();
        data.append("action", "getQuestion");
        data.append("mockID", id);
        data.append("userID", localStorage.getItem("userUID"));

        fetch(API_URL + '/user/Questions.php', {
            method: "POST",
            body: data
        })
        .then((res) => {
            if (!res.ok) {
                throw new Error(`HTTP error! Status: ${res.status}`);
            }
            return res.json();
        })
        .then((data) => {
            if (data.status === true && Array.isArray(data.questions)) {
                setQuestions(data.questions);
            } else {
                setQuestions([]);
                showAlert("warning","Subject not purchased, kindly purchase before you can attempt.");
                window.history.back();
                console.error("No data found or API response is incorrect:", data);
            }
            setIsLoading(false);
        })
        .catch((error) => {
            console.error("Error while fetching questions:", error);
            setQuestions([]);
            setIsLoading(false);
        });
    }, [id]);

    const handleOptionChange = (questionId, option) => {
        setAnswers(prevAnswers => ({
            ...prevAnswers,
            [questionId]: option
        }));
    };

    const submitQuiz = () => {
        setIsLoading(true);
        const userAttempts = questions.map((question) => ({
            id: question.id,
            question: question.question,
            options: {
                a: question.a,
                b: question.b,
                c: question.c,
                d: question.d,
            },
            correctAnswer: question.correct,
            userAnswer: answers[question.id] || null,
            isCorrect: answers[question.id] === question.correct,
            year: question.year,
        }));
        const data = new FormData();
        data.append("action", "saveResult");
        data.append("data", JSON.stringify(userAttempts, null, 2));
        data.append("uid", localStorage.getItem("userUID"));
        data.append("mockID", id);
        fetch(API_URL + "/user/Result.php", {
            method: "POST",
            body: data
        })
        .then(res  => {return res.json()})
        .then(data => {
            if(data.status == true) {
                showAlert("success", "Mock test has been submitted successfully.");
                setTimeout(() => {
                    navigate(`/quiz/result/${id}/` + data.attempt);
                }, 2000);
            } else {
                showAlert("error", "We are unable to store your mock test. Maximum Attempt Reached.");
            }
            setIsLoading(false);
        })
        console.log("User Attempts:", JSON.stringify(userAttempts, null, 2));
    };

    const currentQuestion = questions[currentQuestionIndex];

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div>
            <Header />

            <div className="container p-5">
                {/* Mobile question navigator */}
                <div className="d-md-none mb-3">
                    <div className="d-flex overflow-auto">
                        <ul className="list-group list-group-horizontal flex-wrap">
                            {questions.map((q, index) => (
                                <li
                                    key={q.id}
                                    className={`list-group-item text-center m-1 p-2 ${index === currentQuestionIndex ? 'active' : ''} ${answers[q.id] ? 'list-group-item-success' : 'list-group-item-light'}`}
                                    onClick={() => setCurrentQuestionIndex(index)}
                                    style={{ cursor: 'pointer', width: '40px', height: '40px', lineHeight: '25px', fontSize: '14px', backgroundColor: answers[q.id] ? 'green' : index === currentQuestionIndex ? 'lightblue' : 'white', color: answers[q.id] ? 'white' : 'black' }}
                                >
                                    {index + 1}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <button className="btn btn-danger mt-3 w-100" onClick={submitQuiz}>Submit</button>
                </div>

                <div className="row">
                    <div className="col-md-8">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">
                                    Question {currentQuestionIndex + 1} {currentQuestion?.year ? `(${currentQuestion.year})` : ''}
                                </h5>
                                <p 
                                    className="card-text mb-4" 
                                    dangerouslySetInnerHTML={{ __html: currentQuestion?.question.replace(/\\/g, '') }} 
                                />
                                <form>
                                    {['a', 'b', 'c', 'd'].map(option => (
                                        <div className="form-check mb-2" key={option}>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name={`question-${currentQuestion?.id}`}
                                                id={`option-${option}`}
                                                value={option}
                                                checked={answers[currentQuestion?.id] === option}
                                                onChange={() => handleOptionChange(currentQuestion?.id, option)}
                                            />
                                            <label className="form-check-label" htmlFor={`option-${option}`}>
                                                <span dangerouslySetInnerHTML={{ __html: currentQuestion?.[option]?.replace(/\\/g, '') }} />
                                            </label>
                                        </div>
                                    ))}
                                </form>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between mt-3">
                            {currentQuestionIndex > 0 && (
                                <button
                                    className="btn btn-secondary"
                                    onClick={() => setCurrentQuestionIndex(prev => Math.max(prev - 1, 0))}
                                >
                                    Previous
                                </button>
                            )}
                            {currentQuestionIndex < questions.length - 1 && (
                                <button
                                    className="btn btn-primary"
                                    onClick={() => setCurrentQuestionIndex(prev => Math.min(prev + 1, questions.length - 1))}
                                >
                                    Next
                                </button>
                            )}
                        </div>
                    </div>

                    <div className="col-md-4 d-none d-md-block">
                        <h5>Questions</h5>
                        <div className="d-flex overflow-auto">
                            <ul className="list-group list-group-horizontal flex-wrap">
                                {questions.map((q, index) => (
                                    <li
                                        key={q.id}
                                        className={`list-group-item text-center m-1 p-2 ${index === currentQuestionIndex ? 'active' : ''} ${answers[q.id] ? 'list-group-item-success' : 'list-group-item-light'}`}
                                        onClick={() => setCurrentQuestionIndex(index)}
                                        style={{ cursor: 'pointer', width: '40px', height: '40px', lineHeight: '25px', fontSize: '14px', backgroundColor: answers[q.id] ? 'green' : index === currentQuestionIndex ? 'lightblue' : 'white', color: answers[q.id] ? 'white' : 'black' }}
                                    >
                                        {index + 1}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <button className="btn btn-danger mt-3 w-100" onClick={submitQuiz}>Submit</button>
                    </div>
                </div>
            </div>
            <p className='text-center text-muted'>Contact us on support@mcqkaro.com or 8289088550 for any discrepancies in questions or answers</p>
        </div>
    );
}
