import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { useNavigate } from "react-router-dom";
import AdminHeader from "./adminHeader";
import Swal from "sweetalert2";
import { API_URL, Loader } from "../App";
import { useParams } from 'react-router-dom';

export default function ManageSubjects() {
    const [isLoading, setLoading] = useState(false);
    const [Subjectid, setSubjectid] = useState("");
    const [SubjectName, setSubjectName] = useState("");
    const [SubjectPrice, setSubjectPrice] = useState("");
    const [SubjectImage, setSubjectImage] = useState("");
    const [SubjectList, setSubjectList] = useState([]);
    const [BoardList, setBoardList] = useState([]);
    const [selectedBoard, setselectedBoard] = useState("");

    const [addForm, setaddForm] = useState(true);

    const { id } = useParams();


    useEffect(() => {
        const data = new FormData();
        data.append("action", "fetch_subjects_by_stream");
        setLoading(true);
        fetch(API_URL + '/admin/Categories.php', {
            method: "POST",
            body: data
        })
            .then(res => { return res.json() })
            .then(data => {
                if (data.status == true) {
                    setBoardList(data.data);
                } else {
                   // showAlert("warning", "No Subject found, kindly add a board firts.")
                }
            })
    }, [])

    const showAlert = (icon, text) => {
        Swal.fire({
            text: text,
            icon: icon,
            timer: 2000, // 2 seconds
            timerProgressBar: true,
            showConfirmButton: false,
            showCancelButton: false
        }).then((result) => {
            // This code will run when the timer expires
            if (result.dismiss === Swal.DismissReason.timer) {
            }
        });
    };

    useEffect(() => {

        setLoading(true);
        const data = new FormData();
        data.append("action", "fetch_subjects_by_stream");
        data.append("streamID", id)

        fetch(`${API_URL}/admin/Categories.php`, {
            method: "POST",
            body: data
        })

            .then((response) => response.json())
            .then((data) => {
                if (data.status === true) {
                    setSubjectList(data.data);
                } else {
                    console.error('Error fetching Subjects:', data.message); // Log or handle the error message
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching Subjects:', error); // Log the fetch error
                setLoading(false);
            });
    }, [id]); // Include id in the dependency array

    function addSubject() {

        setLoading(true);
        const data = new FormData();
        data.append("action", "add_category_three")
        data.append("name", SubjectName);
        data.append("image", SubjectImage);
        data.append("price", SubjectPrice);
        data.append("streamID", id);

        fetch(API_URL + "/admin/Categories.php", {
            method: 'POST',
            body: data
        })
            .then(function (res) { return res.json(); })
            .then(function (data) {
                if (data.status === true) {
                    showAlert("success", data.message);
                    window.location.reload();
                } else {
                    showAlert("warning", data.message);
                }
                setLoading(false);
            })
            .catch(function (err) {
                setLoading(false);
                showAlert("warning", "Add all details properly or Subject already exists.");
            });
    }

    function editSubject() {
        setLoading(true);
        const data = new FormData();
        data.append("action", "edit_category_three")
        data.append("id", Subjectid);
        data.append("price", SubjectPrice);
        data.append("name", SubjectName);
        data.append("image", SubjectImage);
        data.append("streamID", id)

        fetch(API_URL + "/admin/Categories.php", {
            method: 'POST',
            body: data
        })
            .then(function (res) { return res.json(); })
            .then(function (data) {
                if (data.status === true) {
                    showAlert("success", "Subject edited successfully");
                    window.location.reload();
                } else {
                    showAlert("warning", "Add all details properly.");
                }
                setLoading(false);
            })
            .catch(function (err) {
                setLoading(false);
                showAlert("warning", "Add all details properly.");
            });
    }

    function deleteSubject(id) {
        setLoading(true);
        const data = new FormData();
        data.append("action", "delete_category_two");
        data.append("streamID", id);

        fetch(API_URL + "/admin/Categories.php", {
            method: 'POST',
            body: data
        })
            .then(function (res) { return res.json(); })
            .then(function (data) {
                if (data.status === true) {
                    showAlert("success", "Subject deleted successfully");
                    window.location.reload();
                } else {
                    showAlert("warning", "Add all details properly.");
                }
                setLoading(false);
            })
            .catch(function (err) {
                setLoading(false);
                showAlert("warning", "Add all details properly.");
            });
    }

    return (
        <div style={{ marginBottom: 150 }}>
            <Loader loading={isLoading} />
            <AdminHeader />
            <div className="container ">
                {addForm == true ?
                    <div className="card p-3" >
                        <p className="fs-5 text-center">Add Subject</p>
                        <input value={SubjectName} onChange={(e) => {
                            setSubjectName(e.target.value);
                        }} className="form-control mb-2" placeholder="Subject Name" />
                        <input value={SubjectPrice} onChange={(e) => {
                            setSubjectPrice(e.target.value);
                        }} className="form-control mb-2" placeholder="Subject Price (0 for Free)" />
                        <input value={SubjectImage} onChange={(e) => {
                            setSubjectImage(e.target.value);
                        }} className="form-control mb-2" placeholder="Image Link" />
                        
                        <button onClick={addSubject} className="btn btn-primary w-100">Save</button>
                    </div>
                    : <div className="card p-3" >
                        <p className="fs-5 text-center">Edit Subject</p>
                        <input value={SubjectName} onChange={(e) => {
                            setSubjectName(e.target.value);
                        }} className="form-control mb-2" placeholder="Subject Name" />
                        <input value={SubjectPrice} onChange={(e) => {
                            setSubjectPrice(e.target.value);
                        }} className="form-control mb-2" placeholder="Subject Price (0 for Free)" />
                        <input value={SubjectImage} onChange={(e) => {
                            setSubjectImage(e.target.value);
                        }} className="form-control mb-2" placeholder="Image Link" />
                        
                        <button onClick={editSubject} className="btn btn-primary w-100 mb-2">Save</button>
                        <button onClick={() => {
                            setSubjectid("");
                            setSubjectImage("");
                            setSubjectName("")
                            setSubjectPrice(0);
                            setaddForm(true)
                        }} className="btn btn-danger w-100">Close</button>
                    </div>
                }

                <div className='mt-3'>
                    <div className="table-responsive rounded rounded-3">
                        <table className='table table-striped table-bordered'>
                            <thead>
                                <tr>
                                    <th>Subject Name</th>
                                    <th>Stream</th>
                                    <th>Image Link</th>
                                    <th>Price</th>
                                    <th className="text-end">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {SubjectList.map((Subject, index) => (
                                    <tr key={index}>
                                        <td>{Subject.name}</td>
                                        <td>{localStorage.getItem("stream")}</td>
                                        <td>{Subject.image}</td>
                                        <td>₹{Subject.price}</td>
                                        <td className='m-0 p-2' style={{ width: "30%" }}>
                                            <div className='d-flex justify-content-end gap-2'>
                                            <button className='btn btn-success' onClick={() => {
                                                    window.location.href = "/admin/mocks/" + Subject.id;
                                                    localStorage.setItem("mock", Subject.name)
                                                }}>Add Mock</button>
                                                <button className='btn btn-warning' onClick={() => {
                                                    setSubjectid(Subject.id);
                                                    setSubjectName(Subject.name);
                                                    setSubjectImage(Subject.image);
                                                    setSubjectPrice(Subject.price)
                                                    setaddForm(false)
                                                }}>Edit</button>
                                                <button onClick={() => {
                                                    deleteSubject(Subject.id)
                                                }} className='btn btn-danger'>Delete</button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
