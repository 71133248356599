import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Swal from "sweetalert2";
import { API_URL } from "../App"; // Ensure this path is correct

const AdminLogin = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setLoading] = useState(false);

        function login() {
            const data = new FormData();
            data.append("email", email);
            data.append("password", password);
            fetch(`${API_URL}/admin/Login.php`, {
                method: "POST",
                body: data
            })
            .then(res => {return res.json()})
            .then(data => {
                if(data.status == true) {
                    localStorage.setItem("key", data.key);
                    window.location.href = "/admin/dashboard";
                } else {
                    Swal.fire({
                        title: "Error",
                        text: data.message,
                        icon: "error",
                    });
                }
            })
        }


    return (
        <div className="container mt-5">
            <div className="row justify-content-center">
                <div className="col-md-4">
                    <div className="card p-4">
                        <h4 className="text-center mb-3">Login</h4>
                        <input
                            type="email"
                            className="form-control mb-2"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <input
                            type="password"
                            className="form-control mb-2"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <button
                            onClick={login}
                            className="btn btn-primary w-100"
                            disabled={isLoading}
                        >
                            {isLoading ? "Loading..." : "Login"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminLogin;
