import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { useNavigate } from "react-router-dom";
import AdminHeader from "./adminHeader";
import Swal from "sweetalert2";
import { API_URL, Loader } from "../App";

export default function ManageBoards() {
    const [isLoading, setLoading] = useState(false);
    const [Boardid, setBoardid] = useState("");
    const [BoardName, setBoardName] = useState("");
    const [BoardImage, setBoardImage] = useState("");
    const [BoardList, setBoardList] = useState([]);

    const [addForm, setaddForm] = useState(true);

    const showAlert = (icon, text) => {
        Swal.fire({
            text: text,
            icon: icon,
            timer: 2000, // 2 seconds
            timerProgressBar: true,
            showConfirmButton: false,
            showCancelButton: false
        }).then((result) => {
            // This code will run when the timer expires
            if (result.dismiss === Swal.DismissReason.timer) {
            }
        });
    };

    useEffect(() => {
        setLoading(true);

        const data = new FormData();
        data.append("action", "fetch_all_boards")
        
        fetch(API_URL + "/admin/Categories.php", {
            method: "POST",
            body: data
        })
        .then((response) => response.json())
        .then((data) => {
            setBoardList(data.data);
            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
        });
    }, []);

    function addBoard() {
        setLoading(true);
        const data = new FormData();
        data.append("action", "add_category_one")
        data.append("name", BoardName);
        data.append("image", BoardImage);

        fetch(API_URL + "/admin/Categories.php", {
            method: 'POST',
            body: data
        })
        .then(function(res) { return res.json(); })
        .then(function(data) {
            if(data.status === true) {
                showAlert("success", data.message);
                window.location.reload();
            } else {
                showAlert("warning", data.message);
            }
            setLoading(false);
        })
        .catch(function(err) {
            setLoading(false);
            showAlert("warning", "Add all details properly or Board already exists.");
        });
    }

    function editBoard() {
        setLoading(true);
        const data = new FormData();
        data.append("action", "edit_category_one")
        data.append("id", Boardid);
        data.append("name", BoardName);
        data.append("image", BoardImage);

        fetch(API_URL + "/admin/Categories.php", {
            method: 'POST',
            body: data
        })
        .then(function(res) { return res.json(); })
        .then(function(data) {
            if(data.status === true) {
                showAlert("success", "Board edited successfully");
                window.location.reload();
            } else {
                showAlert("warning", "Add all details properly.");
            }
            setLoading(false);
        })
        .catch(function(err) {
            setLoading(false);
            showAlert("warning", "Add all details properly.");
        });
    }

    function deleteBoard(id) {
        setLoading(true);
        const data = new FormData();
        data.append("action", "delete_category_one");
        data.append("id", id);

        fetch(API_URL + "/admin/Categories.php", {
            method: 'POST',
            body: data
        })
        .then(function(res) { return res.json(); })
        .then(function(data) {
            if(data.status === true) {
                showAlert("success", "Board deleted successfully");
                window.location.reload();
            } else {
                showAlert("warning", "Add all details properly.");
            }
            setLoading(false);
        })
        .catch(function(err) {
            setLoading(false);
            showAlert("warning", "Add all details properly.");
        });
    }

    return(
        <div style={{marginBottom:150}}>
            <Loader loading={isLoading}/>
            <AdminHeader />
            <div className="container ">
                {addForm == true ? 
                    <div className="card p-3" >
                        <p className="fs-5 text-center">Add Board</p>
                        <input value={BoardName} onChange={(e) => {
                            setBoardName(e.target.value);
                        }} className="form-control mb-2" placeholder="Board Name"/>
                        <input value={BoardImage} onChange={(e) => {
                            setBoardImage(e.target.value);
                        }} className="form-control mb-2" placeholder="Image Link"/>
                        <button onClick={addBoard} className="btn btn-primary w-100">Save</button>
                    </div>
                : <div className="card p-3" >
                    <p className="fs-5 text-center">Edit Board</p>
                    <input value={BoardName} onChange={(e) => {
                        setBoardName(e.target.value);
                    }} className="form-control mb-2" placeholder="Board Name"/>
                    <input value={BoardImage} onChange={(e) => {
                        setBoardImage(e.target.value);
                    }} className="form-control mb-2" placeholder="Image Link"/>
                    <button onClick={editBoard} className="btn btn-primary w-100 mb-2">Save</button>
                    <button onClick={() => {
                        setBoardid("");
                        setBoardImage("");
                        setBoardName("")
                        setaddForm(true)
                    }} className="btn btn-danger w-100">Close</button>
                </div>
            }
                
                <div className='mt-3'>
                    <div className="table-responsive rounded rounded-3">
                        <table className='table table-striped table-bordered'>
                            <thead>
                                <tr>
                                    <th>Board Name</th>
                                    <th>Image Link</th>
                                    <th className="text-end">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {BoardList.map((Board, index) => (
                                    <tr key={index}>
                                        <td>{Board.name}</td>
                                        <td>{Board.image}</td>
                                        <td className='m-0 p-2' style={{width: "20%"}}>
                                            <div className='d-flex justify-content-end gap-2'>
                                                <button className='btn btn-warning' onClick={() => {
                                                    setBoardid(Board.id);
                                                    setBoardName(Board.name);
                                                    setBoardImage(Board.image);
                                                    setaddForm(false)
                                                }}>Edit</button>
                                                <button onClick={() => {
                                                    deleteBoard(Board.id)
                                                }} className='btn btn-danger'>Delete</button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
