import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { useNavigate } from 'react-router-dom';
import Header from "./Header.jsx";
import { API_URL, Loader } from "../App.js";
import { useParams } from 'react-router-dom';
import { FaCheckCircle, FaStar } from 'react-icons/fa';
import Swal from "sweetalert2";

export default function Mocks() {
    const { id } = useParams();
    const showAlert = (icon, text) => {
        Swal.fire({
            text: text,
            icon: icon,
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
        });
    };
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    function calculateAvg(total_scored, total_marks) {
        if (total_marks == 0 || total_marks == "N/A") {
            return "N/A"
        }
        let percentage = (total_scored / total_marks) * 100;
        console.log(percentage)
        if (percentage < 25) {
            return "Very Poor";
        } else if (percentage >= 25 && percentage < 50) {
            return "Poor";
        } else if (percentage >= 50 && percentage < 70) {
            return "Average";
        } else if (percentage >= 70 && percentage < 90) {
            return "Good";
        } else if (percentage >= 90 && percentage <= 100) {
            return "Excellent";
        }

    }

    function move(id, isFree, attempt1, attempt2) {
        if (isFree) {
            Swal.fire({
                title: 'General Instructions',
                html: `
                <p>1) The paper consists of multiple choice questions.
                <p>2) Every question has 4 options and one correct answer.
                <p>3) Read the questions carefully and choose the most appropiate option.
                <p>4) There is no negative marking for any incorrect answer.
               <p>5)It is advisable to attempt the mock test on the basis of your own knowledge and avoid any cheating.
                <p>6) In case of any discrepency in a question or an answer, kindly mail us at support@mcqkaro.com or whatsapp us at 8289088550.

                <p>Chalo ab MCQ Karo!</p>
                `,
                showCancelButton: true,
                confirmButtonText: 'Start',
                cancelButtonText: 'Cancel',
                allowOutsideClick: false,
                allowEscapeKey: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    console.log("Starting quiz...");
                    navigate(`/mock/start/${id}`);

                } else {
                    console.log("Quiz canceled");
                }
            });
        } else {
            if (attempt1 && attempt2) {
                showAlert("warning", "You have reached the maximum number of attempts.");
            } else {
                Swal.fire({
                    title: 'General Instructions',
                    html: `
                        <p>1) The paper consists of multiple choice questions.</p>
                        <p>2) Every question has 4 options and one correct answer.</p>
                        <p>3) Read the questions carefully and choose the most appropriate option.</p>
                        <p>4) There is no negative marking for any incorrect answer.</p>
                        <p>5) In case of any discrepancy in a question or an answer, kindly mail us at support@mcqkaro.com mail or WhatsApp us at 8289088550.</p>
                        <p>Chalo ab MCQ Karo!</p>
                    `,
                    showCancelButton: true,
                    confirmButtonText: 'Proceed',
                    cancelButtonText: 'Cancel',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        console.log("Starting quiz...");
                        navigate(`/mock/start/${id}`);

                    } else {
                        console.log("Quiz canceled");
                    }
                });
            }
        }
    }

    function goResult(e, id, step) {
        e.stopPropagation();
        setIsLoading(true);
        navigate(`/quiz/result/${id}/${step}`);
    }

    useEffect(() => {
        setIsLoading(true);
        const data = new FormData();
        data.append("action", "fetch_all_mocks");
        data.append("subjectID", id);
        data.append("uid", localStorage.getItem("userUID"));
        fetch(API_URL + '/user/Mocks.php', {
            method: "POST",
            body: data
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.status === true) {
                    setCategories(data.data);
                } else {
                    setCategories([]);
                }
                setIsLoading(false);
            });
    }, [id]);

    return (
        <div>
            <Header />
            <Loader loading={isLoading} />
            <div className="container">
                <h5 className="text-center mb-3">Free Mock Tests</h5>
                {categories.length > 0 && categories.some(category => category.is_free === 1) ? (
                    <div className="row g-3">
                        {categories.filter(category => category.is_free === 1).map((category) => (
                            category.attempt1_count === 1 && category.attempt2_count === 1 ? (
                                <div
                                    onClick={() => showAlert("warning", "You have reached the maximum number of attempts.")}
                                    key={category.id}
                                    className="col-12 col-md-6 mb-3"
                                >
                                    <div
                                        className="card"
                                        style={{
                                            transition: "transform 0.2s",
                                            borderRadius: "10px",
                                            marginBottom: "-20px",
                                            backgroundColor: "#f0f8ff" // Light blue background color
                                        }}
                                    >
                                        {/* Header Section */}
                                        <div
                                            style={{
                                                backgroundColor: "black", // Blue header background
                                                color: "white",
                                                padding: "10px",
                                                borderTopLeftRadius: "10px",
                                                borderTopRightRadius: "10px",
                                            }}
                                        >
                                            <p className="fs-5 mb-0">{category.name}</p>
                                            <small>{category.question_count} Questions</small>
                                        </div>

                                        {/* Main Content */}
                                        <div style={{
                                            padding: "10px",
                                            backgroundColor: "#ffffff", // White background for the main content
                                            borderBottomLeftRadius: "10px",
                                            borderBottomRightRadius: "10px"
                                        }}>
                                            <div style={{ backgroundColor: "whitesmoke" }} className="d-flex p-3 rounded-4 justify-content-between align-items-center mb-0">
                                                <div>
                                                    <p className="fs-6 mb-1 text-muted">1st Attempt: {category.attempt1_data == null ? "N/A" : (JSON.parse(category.attempt1_data || "[]").filter(item => item.userAnswer !== null).length)}  {category.attempt1_data == null ? "" : "/" + (JSON.parse(category.attempt1_data || "[]").length)}</p>
                                                    <p className="fs-6 mb-1 text-muted">Marks: {category.attempt1_data == null ? "N/A" : (JSON.parse(category.attempt1_data || "[]").filter(item => item.isCorrect !== false).length)}</p>
                                                </div>
                                                <div>
                                                    <p className="fs-6 mb-1 text-muted">2nd Attempt: {category.attempt2_data == null ? "N/A" : (JSON.parse(category.attempt2_data || "[]").filter(item => item.userAnswer !== null).length)}  {category.attempt2_data == null ? "" : "/" + (JSON.parse(category.attempt2_data || "[]").length)}</p>
                                                    <p className="fs-6 mb-1 text-muted">Marks: {category.attempt2_data == null ? "N/A" : (JSON.parse(category.attempt2_data || "[]").filter(item => item.isCorrect !== false).length)}</p>
                                                </div>
                                            </div>

                                            {/* Divider Line */}
                                            <div style={{ borderColor: "whitesmoke", color: "white", position: "absolute", top: 0, right: 10 }} className="p-2 rounded-2 color-white d-flex align-items-center justify-content-center mt-2">
                                                <FaStar style={{ marginRight: '0.5rem' }} />
                                                <p className="mb-0 text-center">
                                                    {calculateAvg(
                                                        category.attempt1_data == null ? "N/A" : JSON.parse(category.attempt1_data || "[]").filter((item) => item.isCorrect == true).length,
                                                        category.attempt1_data == null ? "N/A" : JSON.parse(category.attempt1_data || "[]").length
                                                    )}
                                                </p>
                                            </div>
                                            <div className='mb-2'>
                                            </div>
                                            {category.attempt1_count == 1 ? (
                                                <button onClick={(e) => { goResult(e, category.id, 1) }} className='btn btn-outline-dark w-100 mb-2'>1st Attempt Result</button>
                                            ) : (
                                                <div></div>
                                            )}
                                            {category.attempt2_count == 1 ? (
                                                <button onClick={(e) => { goResult(e, category.id, 2) }} className='btn btn-outline-dark w-100'>2nd Attempt Result</button>
                                            ) : (
                                                <div></div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div
                                    onClick={() => move(category.id, true, category.attempt1_count, category.attempt2_count)}
                                    key={category.id}
                                    className="col-12 col-md-6 mb-3"
                                >
                                    <div
                                        className="card"
                                        style={{
                                            transition: "transform 0.2s",
                                            borderRadius: "10px",
                                            marginBottom: "-20px",
                                            backgroundColor: "#f0f8ff" // Light blue background color
                                        }}
                                    >
                                        {/* Header Section */}
                                        <div
                                            style={{
                                                backgroundColor: "black", // Blue header background
                                                color: "white",
                                                padding: "10px",
                                                borderTopLeftRadius: "10px",
                                                borderTopRightRadius: "10px",
                                            }}
                                        >
                                            <p className="fs-5 mb-0">{category.name}</p>
                                            <small>{category.question_count} Questions</small>
                                        </div>

                                        {/* Main Content */}
                                        <div style={{
                                            padding: "10px",
                                            backgroundColor: "#ffffff", // White background for the main content
                                            borderBottomLeftRadius: "10px",
                                            borderBottomRightRadius: "10px"
                                        }}>
                                            <div style={{ backgroundColor: "whitesmoke" }} className="d-flex p-3 rounded-4 justify-content-between align-items-center mb-0">
                                                <div>
                                                    <p className="fs-6 mb-1 text-muted">1st Attempt: {category.attempt1_data == null ? "N/A" : (JSON.parse(category.attempt1_data || "[]").filter(item => item.userAnswer !== null).length)}  {category.attempt1_data == null ? "" : "/" + (JSON.parse(category.attempt1_data || "[]").length)}</p>
                                                    <p className="fs-6 mb-1 text-muted">Marks: {category.attempt1_data == null ? "N/A" : (JSON.parse(category.attempt1_data || "[]").filter(item => item.isCorrect !== false).length)}</p>
                                                </div>
                                                <div>
                                                    <p className="fs-6 mb-1 text-muted">2nd Attempt: {category.attempt2_data == null ? "N/A" : (JSON.parse(category.attempt2_data || "[]").filter(item => item.userAnswer !== null).length)}  {category.attempt2_data == null ? "" : "/" + (JSON.parse(category.attempt2_data || "[]").length)}</p>
                                                    <p className="fs-6 mb-1 text-muted">Marks: {category.attempt2_data == null ? "N/A" : (JSON.parse(category.attempt2_data || "[]").filter(item => item.isCorrect !== false).length)}</p>
                                                </div>
                                            </div>

                                            {/* Divider Line */}
                                            <div style={{ borderColor: "whitesmoke", color: "white", position: "absolute", top: 0, right: 10 }} className="p-2 rounded-2 color-white d-flex align-items-center justify-content-center mt-2">
                                                <FaStar style={{ marginRight: '0.5rem' }} />
                                                <p className="mb-0 text-center">
                                                    {calculateAvg(
                                                        category.attempt1_data == null
                                                            ? "N/A"
                                                            : JSON.parse(category.attempt1_data || "[]").filter((item) => item.isCorrect == true).length,
                                                        category.attempt1_data == null
                                                            ? "N/A"
                                                            : JSON.parse(category.attempt1_data || "[]").length
                                                    )}
                                                </p>
                                            </div>
                                            <div className='mb-2'>
                                            </div>
                                            {category.attempt1_count == 1 ? (
                                                <button onClick={(e) => { goResult(e, category.id, 1) }} className='btn btn-outline-dark w-100 mb-2'>1st Attempt Result</button>
                                            ) : (
                                                <div></div>
                                            )}
                                            {category.attempt2_count == 1 ? (
                                                <button onClick={(e) => { goResult(e, category.id, 2) }} className='btn btn-outline-dark w-100'>2nd Attempt Result</button>
                                            ) : (
                                                <div></div>
                                            )}
                                        </div>

                                    </div>
                                </div>
                            )
                        ))}
                    </div>
                ) : (
                    <p className="text-center mt-3">No free categories found</p>
                )}
            </div>

            <div className="container mt-4">
                <h5 className="text-center mb-3">Chapter - Wise Premium Mock Tests</h5>
                {categories.length > 0 && categories.some(category => category.is_free === 0) ? (
                    <div className="row g-3">
                        {categories.filter(category => category.is_free === 0).map((category) => (
                            <div
                                onClick={() => move(category.id, false, category.attempt1_count, category.attempt2_count)}
                                key={category.id}
                                className="col-12 col-md-6 mb-3"
                            >
                                <div
                                    className="card"
                                    style={{
                                        transition: "transform 0.2s",
                                        borderRadius: "10px",
                                        marginBottom: "-20px",
                                        backgroundColor: "#f0f8ff" // Light blue background color
                                    }}
                                >
                                    {/* Header Section */}
                                    <div
                                        style={{
                                            backgroundColor: "black", // Blue header background
                                            color: "white",
                                            padding: "10px",
                                            borderTopLeftRadius: "10px",
                                            borderTopRightRadius: "10px",
                                        }}
                                    >
                                        <p className="fs-5 mb-0">{category.name}</p>
                                        <small>{category.question_count} Questions</small>
                                    </div>

                                    {/* Main Content */}
                                    <div style={{
                                        padding: "10px",
                                        backgroundColor: "#ffffff", // White background for the main content
                                        borderBottomLeftRadius: "10px",
                                        borderBottomRightRadius: "10px"
                                    }}>
                                        <div style={{ backgroundColor: "whitesmoke" }} className="d-flex p-3 rounded-4 justify-content-between align-items-center mb-0">
                                            <div>
                                                <p className="fs-6 mb-1 text-muted">1st Attempt: {category.attempt1_data == null ? "N/A" : (JSON.parse(category.attempt1_data || "[]").filter(item => item.userAnswer !== null).length)}  {category.attempt1_data == null ? "" : "/" + (JSON.parse(category.attempt1_data || "[]").length)}</p>
                                                <p className="fs-6 mb-1 text-muted">Marks: {category.attempt1_data == null ? "N/A" : (JSON.parse(category.attempt1_data || "[]").filter(item => item.isCorrect !== false).length)}</p>
                                            </div>
                                            <div>
                                                <p className="fs-6 mb-1 text-muted">2nd Attempt: {category.attempt2_data == null ? "N/A" : (JSON.parse(category.attempt2_data || "[]").filter(item => item.userAnswer !== null).length)}  {category.attempt2_data == null ? "" : "/" + (JSON.parse(category.attempt2_data || "[]").length)}</p>
                                                <p className="fs-6 mb-1 text-muted">Marks: {category.attempt2_data == null ? "N/A" : (JSON.parse(category.attempt2_data || "[]").filter(item => item.isCorrect !== false).length)}</p>
                                            </div>
                                        </div>

                                        {/* Divider Line */}
                                        <div style={{ borderColor: "whitesmoke", color: "white", position: "absolute", top: 0, right: 10 }} className="p-2 rounded-2 color-white d-flex align-items-center justify-content-center mt-2">
                                            <FaStar style={{ marginRight: '0.5rem' }} />
                                            <p className="mb-0 text-center">
                                                {calculateAvg(
                                                    Array.isArray(category.attempt1_data)
                                                        ? category.attempt1_data.filter((item) => item.isCorrect !== false).length
                                                        : JSON.parse(category.attempt1_data || "[]").filter((item) => item.isCorrect == true).length,
                                                    Array.isArray(category.attempt1_data)
                                                        ? category.attempt1_data.length
                                                        : JSON.parse(category.attempt1_data || "[]").length
                                                )}
                                            </p>                                        </div>
                                        <div className='mb-2'>
                                        </div>
                                        {category.attempt1_count == 1 ? (
                                            <button onClick={(e) => { goResult(e, category.id, 1) }} className='btn btn-outline-dark w-100 mb-2'>1st Attempt Result</button>
                                        ) : (
                                            <div></div>
                                        )}
                                        {category.attempt2_count == 1 ? (
                                            <button onClick={(e) => { goResult(e, category.id, 2) }} className='btn btn-outline-dark w-100'>2nd Attempt Result</button>
                                        ) : (
                                            <div></div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p className="text-center mt-3">No paid categories found</p>
                )}
            </div>
        </div>
    );
}
