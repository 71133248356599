import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { useNavigate, useParams } from 'react-router-dom';
import { API_URL, Loader } from "../App.js";
import Header from './Header.jsx';

export default function Result() {
    const [result, setResult] = useState([]);
    const [resutl2, setResult2] = useState([]);
    
    
}

