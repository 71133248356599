import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { useNavigate, useParams } from 'react-router-dom';
import { API_URL, Loader } from "../App.js";
import Header from './Header.jsx';
import Swal from "sweetalert2";

export default function QuizResult() {
    const { id, attempt } = useParams();
    const navigate = useNavigate();
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(true); // Set to true for result review
    const [totalMarks, setTotalMarks] = useState(0);
    const [marksObtained, setMarksObtained] = useState(0);
    const [performanceStatus, setPerformanceStatus] = useState('');

    const showAlert = (icon, text) => {
        Swal.fire({
            text: text,
            icon: icon,
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
        });
    };

    useEffect(() => {
        setIsLoading(true);

        const data = new FormData();
        if(attempt == 1) {
            data.append("action", "getResult1");
        } else {
            data.append("action", "getResult2");
        }
        data.append("mockID", id);
        data.append("uid", localStorage.getItem("userUID"));

        fetch(API_URL + '/user/Result.php', {
            method: "POST",
            body: data
        })
        .then((res) => {
            if (!res.ok) {
                throw new Error(`HTTP error! Status: ${res.status}`);
            }
            return res.json();
        })
        .then((data) => {
            if (data.status === true) {
                const questionsArray = JSON.parse(data.data);
                if (Array.isArray(questionsArray)) {
                    const formattedQuestions = questionsArray.map(q => ({
                        id: q.id,
                        question: q.question,
                        options: q.options,
                        correctAnswer: q.correctAnswer,
                        userAnswer: q.userAnswer || null,
                        isCorrect: q.isCorrect,
                        year: q.year || null,
                    }));
                    setQuestions(formattedQuestions);

                    // Calculate total marks and marks obtained
                    const total = formattedQuestions.length;
                    const obtained = formattedQuestions.filter(q => q.isCorrect).length;
                    setTotalMarks(total);
                    setMarksObtained(obtained);

                    // Determine performance status
                    const percentage = (obtained / total) * 100;
                    let status = '';


                    if (percentage < 25) {
                        status = "Very Poor";
                    } else if (percentage >= 25 && percentage < 50) {
                        status = "Poor";
                    } else if (percentage >= 50 && percentage < 70) {
                        status = "Average";
                    } else if (percentage >= 70 && percentage < 90) {
                        status = "Good";
                    } else if (percentage >= 90 && percentage <= 100) {
                        status = "Excellent";
                    }

                    setPerformanceStatus(status);
                } else {
                    setQuestions([]);
                    showAlert("warning", "No data found or API response is incorrect.");
                    console.error("Parsed data is not an array:", questionsArray);
                }
            } else {
                setQuestions([]);
                showAlert("warning", "You have not attempted the mock test yet.");
                console.error("No data found or API response is incorrect:", data);
            }
            setIsLoading(false);
        })
        .catch((error) => {
            console.error("Error while fetching questions:", error);
            setQuestions([]);
            setIsLoading(false);
        });
    }, [id]);

    const currentQuestion = questions[currentQuestionIndex];

    if (isLoading) {
        return <Loader />;
    }

    return (
        <div>
            <Header />

            <div className="container p-5">
                <div className="row mb-4">
                    <div className="col-12">
                        {/* Summary Card */}
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Mock Summary</h5>
                                <div className="d-flex justify-content-between align-items-center mb-2">
                                    <p className="mb-0">Total Questions: {totalMarks}</p>
                                    <p className="mb-0">Marks Obtained: {marksObtained}</p>
                                </div>
                               
                                <h6>Status: 
                                    <span className={`fw-bold ${performanceStatus === 'Excellent' ? 'text-success' : performanceStatus === 'Average' ? 'text-warning' : 'text-danger'}`}>
                                        {performanceStatus}
                                    </span>
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8">
                        <div className="card mb-3">
                            <div className="card-body">
                                <h5 className="card-title">
                                    Question {currentQuestionIndex + 1} {currentQuestion?.year ? `(${currentQuestion.year})` : ''}
                                </h5>
                                <p className="card-text mb-4">{currentQuestion?.question}</p>
                                <form>
                                    {Object.entries(currentQuestion?.options || {}).map(([option, text]) => (
                                        <div className="form-check mb-2" key={option}>
                                            <input
                                                className={`form-check-input ${
                                                    currentQuestion?.userAnswer === option && !currentQuestion.isCorrect
                                                        ? 'border-danger'
                                                        : ''
                                                } ${currentQuestion?.correctAnswer === option ? 'bg-success' : ''}`}
                                                type="radio"
                                                name={`question-${currentQuestion?.id}`}
                                                id={`option-${option}`}
                                                value={option}
                                                checked={currentQuestion?.userAnswer === option}
                                                readOnly
                                            />
                                            <label className="form-check-label" htmlFor={`option-${option}`}>
                                                {text}
                                            </label>
                                        </div>
                                    ))}
                                    {/* Display message for skipped questions */}
                                    {currentQuestion?.userAnswer === null && (
                                        <p className="text-muted">You skipped this question.</p>
                                    )}
                                    {/* Display correct answer if user answered incorrectly */}
                                    {currentQuestion?.userAnswer && !currentQuestion.isCorrect && (
                                        <p className="text-danger">
                                            Correct answer: {currentQuestion.correctAnswer}
                                        </p>
                                    )}
                                </form>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between mt-3">
                            {currentQuestionIndex > 0 && (
                                <button
                                    className="btn btn-secondary"
                                    onClick={() => setCurrentQuestionIndex(prev => Math.max(prev - 1, 0))}
                                >
                                    Previous
                                </button>
                            )}
                            {currentQuestionIndex < questions.length - 1 && (
                                <button
                                    className="btn btn-primary"
                                    onClick={() => setCurrentQuestionIndex(prev => Math.min(prev + 1, questions.length - 1))}
                                >
                                    Next
                                </button>
                            )}
                        </div>
                    </div>

                    <div className="col-md-4 d-none d-md-block">
                        <h5>Questions</h5>
                        <div className="d-flex overflow-auto">
                            <ul className="list-group list-group-horizontal flex-wrap">
                                {questions.map((q, index) => (
                                    <li
                                        key={q.id}
                                        className={`list-group-item text-center m-1 p-2 ${
                                            index === currentQuestionIndex ? 'active' : ''
                                        } ${
                                            isSubmitted && q.isCorrect
                                            ? 'list-group-item-success'
                                            : isSubmitted && q.userAnswer !== null && !q.isCorrect
                                            ? 'list-group-item-danger'
                                            : 'list-group-item-secondary'
                                        }`}
                                        onClick={() => setCurrentQuestionIndex(index)}
                                        style={{ cursor: 'pointer', width: '40px', height: '40px', lineHeight: '25px', fontSize: '14px' }}
                                    >
                                        {index + 1}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
