import './App.css';
import { Route, BrowserRouter as Router, Routes, useNavigate } from 'react-router-dom';
import { useState, useEffect, CSSProperties } from "react";
import React from "react";
import { FadeLoader } from "react-spinners";
import Auth from './auth/login';
import AdminLogin from './adminpanel/login';
import Board from './user/board';
import ManageBoards from './adminpanel/manageBoards';
import ManageStreams from './adminpanel/manageStreams';
import ManageSubjects from './adminpanel/manageSubjects';
import ManageMocks from './adminpanel/manageMockTest';
import ManageQuestions from './adminpanel/manageQuestions';
import { AdminDashboard } from './adminpanel/Dashboard';
import Streams from './user/stream';
import Boards from './user/board';
import Subjects from './user/subject';
import Mocks from './user/mocks';
import Quiz from './user/quiz';
import Result from './user/result';
import Login from './user/login';
import Register from './user/register';
import QuizResult from './user/quizResult';

export const API_URL = "https://mcqkaro.com/api";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Board />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/board" element={<Board />} />
                {/* user routes */}
                <Route path="/streams/:id" element={<Streams />} />
                <Route path="/boards" element={<Boards />} />
                <Route path="/subjects/:id" element={<Subjects />} />
                <Route path="/mocks/:id" element={<Mocks />} />
                <Route path="/mock/start/:id" element={<Quiz />} />
                <Route path="/result/:id" element={<Result />} />
                <Route path="/quiz/result/:id/:attempt" element={<QuizResult />} />

                {/* Routes for Admin Panel */}
                <Route path="/admin/login" element={<AdminLogin />} />
                <Route path="/admin/dashboard" element={<AdminDashboard />} />
                <Route path="/admin/boards" element={<ManageBoards />} />
                <Route path="/admin/streams" element={<ManageStreams />} />
                <Route path="/admin/subjects/:id" element={<ManageSubjects />} />
                <Route path="/admin/mocks/:id" element={<ManageMocks />} />
                <Route path="/admin/questions/:id" element={<ManageQuestions />} />


            </Routes>
        </Router>
    );
}

// @ts-ignore
const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
};

export function Loader({ loading }) {
    const [color, setColor] = useState("#ffffff");
    const [overlay, setOverlay] = useState('block');

    useEffect(() => {
        // Update overlay state based on loading prop
        if (!loading) {
            setOverlay('d-none');
        } else {
            setOverlay('block');
        }
    }, [loading]); // Run this effect whenever loading prop changes

    return (
        <div className={`overlay ${overlay}`}>
            <div className="loader-container">
                <FadeLoader
                    color={color}
                    loading={loading}
                    cssOverride={override}
                    size={50}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                />
            </div>
        </div>
    );
}

export default App;
