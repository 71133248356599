import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { useNavigate } from "react-router-dom";
import AdminHeader from "./adminHeader";
import Swal from "sweetalert2";
import { API_URL, Loader } from "../App";
import { useParams } from 'react-router-dom';

export default function ManageMocks() {
    const { id } = useParams();

    const [isLoading, setLoading] = useState(false);
    const [Mockid, setMockid] = useState("");
    const [MockName, setMockName] = useState("");
    const [MockImage, setMockImage] = useState("");
    const [MockList, setMockList] = useState([]);
    const [BoardList, setBoardList] = useState([]);
    const [selectedBoard, setselectedBoard] = useState("");

    const [addForm, setaddForm] = useState(true);


    const showAlert = (icon, text) => {
        Swal.fire({
            text: text,
            icon: icon,
            timer: 2000, // 2 seconds
            timerProgressBar: true,
            showConfirmButton: false,
            showCancelButton: false
        }).then((result) => {
            // This code will run when the timer expires
            if (result.dismiss === Swal.DismissReason.timer) {
            }
        });
    };

    useEffect(() => {

        setLoading(true);
        const data = new FormData();
        data.append("action", "fetch_all_mocks");
        data.append("subjectID", id);

        fetch(`${API_URL}/admin/MockTests.php`, {
            method: "POST",
            body: data
        })

            .then((response) => response.json())
            .then((data) => {
                if (data.status === true) {
                    setMockList(data.data);
                } else {
                    console.error('Error fetching Mocks:', data.message); // Log or handle the error message
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching Mocks:', error); // Log the fetch error
                setLoading(false);
            });
    }, [id]); // Include id in the dependency array

    function addMock() {

        setLoading(true);
        const data = new FormData();
        data.append("action", "addMock")
        data.append("name", MockName)
        data.append("subjectID", id);

        fetch(API_URL + "/admin/MockTests.php", {
            method: 'POST',
            body: data
        })
            .then(function (res) { return res.json(); })
            .then(function (data) {
                if (data.status === true) {
                    showAlert("success", data.message);
                    window.location.reload();
                } else {
                    showAlert("warning", data.message);
                }
                setLoading(false);
            })
            .catch(function (err) {
                setLoading(false);
                showAlert("warning", "Add all details properly or Mock already exists.");
            });
    }

    function editMock() {
        setLoading(true);
        const data = new FormData();
        data.append("action", "editMock")
        data.append("id", Mockid);
        data.append("name", MockName);

        fetch(API_URL + "/admin/MockTests.php", {
            method: 'POST',
            body: data
        })
            .then(function (res) { return res.json(); })
            .then(function (data) {
                if (data.status === true) {
                    showAlert("success", "Mock edited successfully");
                    window.location.reload();
                } else {
                    showAlert("warning", "Add all details properly.");
                }
                setLoading(false);
            })
            .catch(function (err) {
                setLoading(false);
                showAlert("warning", "Add all details properly.");
            });
    }

    function deleteMock(id) {
        setLoading(true);
        const data = new FormData();
        data.append("action", "deleteMock");
        data.append("id", id);

        fetch(API_URL + "/admin/MockTests.php", {
            method: 'POST',
            body: data
        })
            .then(function (res) { return res.json(); })
            .then(function (data) {
                if (data.status === true) {
                    showAlert("success", "Mock deleted successfully");
                    window.location.reload();
                } else {
                    showAlert("warning", "Add all details properly.");
                }
                setLoading(false);
            })
            .catch(function (err) {
                setLoading(false);
                showAlert("warning", "Add all details properly.");
            });
    }

    return (
        <div style={{ marginBottom: 150 }}>
            <Loader loading={isLoading} />
            <AdminHeader />
            <div className="container ">
                {addForm == true ?
                    <div className="card p-3" >
                        <p className="fs-5 text-center">Add Mock</p>
                        <input value={MockName} onChange={(e) => {
                            setMockName(e.target.value);
                        }} className="form-control mb-2" placeholder="Mock Name" />
                        <button onClick={addMock} className="btn btn-primary w-100">Save</button>
                    </div>
                    : <div className="card p-3" >
                        <p className="fs-5 text-center">Edit Mock</p>
                        <input value={MockName} onChange={(e) => {
                            setMockName(e.target.value);
                        }} className="form-control mb-2" placeholder="Mock Name" />
                        <button onClick={editMock} className="btn btn-primary w-100 mb-2">Save</button>
                        
                        <button onClick={() => {
                            setMockid("");
                            setMockImage("");
                            setMockName("")
                            setaddForm(true)
                        }} className="btn btn-danger w-100">Close</button>
                    </div>
                }

                <div className='mt-3'>
                    <div className="table-responsive rounded rounded-3">
                        <table className='table table-striped table-bordered'>
                            <thead>
                                <tr>
                                    <th>Mock Name</th>
                                    <th>Subject</th>
                                    <th>Questions</th>
                                    <th className="text-end">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {MockList.map((Mock, index) => (
                                    <tr key={index}>
                                        <td>{Mock.name}</td>
                                        <td>{localStorage.getItem("mock")}</td>
                                        <td>{Mock.question_count}</td>
                                        <td className='m-0 p-2' style={{ width: "30%" }}>
                                            <div className='d-flex justify-content-end gap-2'>
                                            <button className='btn btn-success' onClick={() => {
                                                    window.location.href = "/admin/questions/" + Mock.id;
                                                    localStorage.setItem("Mock", Mock.name)
                                                }}>Add Question</button>
                                                <button className='btn btn-warning' onClick={() => {
                                                    setMockid(Mock.id);
                                                    setMockName(Mock.name);
                                                    setMockImage(Mock.image);
                                                    setaddForm(false)
                                                }}>Edit</button>
                                                <button onClick={() => {
                                                    deleteMock(Mock.id)
                                                }} className='btn btn-danger'>Delete</button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
