import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../index.css';
import { API_URL, Loader } from "../App";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import IsLogin from "../user/IsLogin";

export default function Login({ setIsLogin }) {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const showAlert = (icon, text) => {
        Swal.fire({
            text: text,
            icon: icon,
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
        });
    };

    const handleLogin = () => {
        setIsLoading(true);
        const data = new FormData();
        data.append("action", "login");
        data.append("email", email);
        data.append("password", password);
        fetch(`${API_URL}/user/Auth.php`, {
            method: 'POST',
            body: data
        })
        .then(response => response.json())
        .then(data => {
            setIsLoading(false);
            if (data.status) {
                localStorage.setItem('userUID', data.uid);
                navigate('/board');
            } else {
                showAlert('warning', 'Email not verified. Please verify your email.');
            }
        })
        .catch(error => {
            setIsLoading(false);
            showAlert('error', 'An error occurred. Please try again later.');
            console.error('Login error:', error);
        });
    };

    const handleForgotPassword = () => {
        Swal.fire({
            title: 'Enter your email',
            input: 'email',
            inputPlaceholder: 'Enter your email address',
            showCancelButton: true,
            confirmButtonText: 'Send Reset Link',
            cancelButtonText: 'Cancel',
            preConfirm: (email) => {
                if (!email) {
                    Swal.showValidationMessage('Please enter a valid email');
                }
                return email;
            }
        }).then((result) => {
            if (result.isConfirmed) {
                const email = result.value;
                setIsLoading(true);
                const data = new FormData();
                data.append("action", "reset_password");
                data.append("email", email);

                fetch(`${API_URL}/user/Auth.php`, {
                    method: 'POST',
                    body: data
                })
                .then(response => response.json())
                .then(data => {
                    setIsLoading(false);
                    if (data.status) {
                        showAlert('success', 'Password reset email sent successfully.');
                    } else {
                        showAlert('error', data.message || 'Failed to send password reset email.');
                    }
                })
                .catch(error => {
                    setIsLoading(false);
                    showAlert('error', 'An error occurred. Please try again later.');
                    console.error('Forgot Password error:', error);
                });
            }
        });
    };

    return (
        <div>
            <div className="authcontainer bg-dark container justify-content-center align-items-center">
                <IsLogin />
                <Loader loading={isLoading} />
                <div className="mb-4"></div>
                <div className='fs-5 text-center mb-1 text-white'>Welcome Back!</div>
                <div className='fs-6 text-center mb-4 text-white'>
                    Prepare for your exams by chapter wise premium mock tests. <br /> Powered by mcqkaro.com.
                </div>
            </div>

            <div className="auth container card p-4">
                <input 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                    className="form-control mb-2" 
                    placeholder="Email" 
                    type="email"
                />
                <input 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                    className="form-control mb-3" 
                    placeholder="Password" 
                    type="password"
                />
                <button 
                    className="btn btn-primary mb-2"
                    onClick={handleLogin}
                >
                    Login
                </button>
                <p 
                    className="text-center text-primary mb-2"
                    style={{ cursor: 'pointer' }}
                    onClick={handleForgotPassword}
                >
                    Forgot Password?
                </p>
                <p className="text-center">or</p>
                <div className="mb-1"></div>
                <button
                    onClick={() => navigate('/register')}
                    className="btn btn-outline-success text-center p-2"
                >
                    Create Account
                </button>
            </div>
            <div id="recaptcha-container"></div>
            <div className='text-center mt-5' style={{ color: "black", opacity: "0.5", position: "relative", bottom: 15, left: 0, right: 0 }}>
                By continuing, you agree to our Terms & Condition and Privacy Policy.
            </div>
        </div>
    );
}
