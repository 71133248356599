import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { useNavigate } from "react-router-dom";
import AdminHeader from "./adminHeader";
import Swal from "sweetalert2";
import { API_URL, Loader } from "../App";
import { useParams } from 'react-router-dom';

export default function ManageQuestions() {
    const [isLoading, setLoading] = useState(false);
    const [questionId, setQuestionId] = useState("");
    const [questionName, setQuestionName] = useState("");
    const [option1, setOption1] = useState("");
    const [option2, setOption2] = useState("");
    const [option3, setOption3] = useState("");
    const [option4, setOption4] = useState("");
    const [correctOption, setCorrectOption] = useState("");
    const [year, setYear] = useState("");

    const [questionList, setQuestionList] = useState([]);
    const [addForm, setAddForm] = useState(true);

    const { id } = useParams();
    const navigate = useNavigate();

    const showAlert = (icon, text) => {
        Swal.fire({
            text: text,
            icon: icon,
            timer: 2000, // 2 seconds
            timerProgressBar: true,
            showConfirmButton: false,
            showCancelButton: false
        }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
                // Handle the timer expiration if needed
            }
        });
    };

    useEffect(() => {
        setLoading(true);
        const data = new FormData();
        data.append("action", "getQuestion");
        data.append("mockID", id);

        fetch(`${API_URL}/admin/Questions.php`, {
            method: "POST",
            body: data
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === true) {
                    setQuestionList(data.questions); // Fixed this line to access the correct property
                } else {
                    console.error('Error fetching Questions:', data.message); // Log or handle the error message
                }
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching Questions:', error); // Log the fetch error
                setLoading(false);
            });
    }, [id]);

    const addQuestion = () => {
        setLoading(true);
        const data = new FormData();
        data.append("action", "addQuestion");
        data.append("mockID", id);
        data.append("question", questionName);
        data.append("option1", option1);
        data.append("option2", option2);
        data.append("option3", option3);
        data.append("option4", option4);
        data.append("correctOption", correctOption);
        data.append("year", year);

        fetch(`${API_URL}/admin/Questions.php`, {
            method: 'POST',
            body: data
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.status === true) {
                    showAlert("success", data.message);
                    window.location.reload();
                } else {
                    showAlert("warning", data.message);
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                showAlert("warning", "Add all details properly or Question already exists.");
            });
    };

    const editQuestion = () => {
        setLoading(true);
        const data = new FormData();
        data.append("action", "editQuestion");
        data.append("questionID", questionId);
        data.append("mockID", id)
        data.append("question", questionName);
        data.append("option1", option1);
        data.append("option2", option2);
        data.append("option3", option3);
        data.append("option4", option4);
        data.append("correctOption", correctOption);
        data.append("year", year)

        fetch(`${API_URL}/admin/Questions.php`, {
            method: 'POST',
            body: data
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.status === true) {
                    showAlert("success", "Question edited successfully");
                    window.location.reload();
                } else {
                    showAlert("warning", "Add all details properly.");
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                showAlert("warning", "Add all details properly.");
            });
    };

    const deleteQuestion = (id) => {
        setLoading(true);
        const data = new FormData();
        data.append("action", "deleteQuestion");
        data.append("questionID", id);

        fetch(`${API_URL}/admin/Questions.php`, {
            method: 'POST',
            body: data
        })
            .then((res) => res.json())
            .then((data) => {
                if (data.status === true) {
                    showAlert("success", "Question deleted successfully");
                    window.location.reload();
                } else {
                    showAlert("warning", "Add all details properly.");
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                showAlert("warning", "Add all details properly.");
            });
    };

    return (
        <div style={{ marginBottom: 150 }}>
            <Loader loading={isLoading} />
            <AdminHeader />
            <div className="container">
                {addForm ? (
                    <div className="card p-3">
                        <p className="fs-5 text-center">Add Question</p>
                        <input value={questionName} onChange={(e) => setQuestionName(e.target.value)} className="form-control mb-2" placeholder="Question Name" />
                        <input value={option1} onChange={(e) => setOption1(e.target.value)} className="form-control mb-2" placeholder="Option A" />
                        <input value={option2} onChange={(e) => setOption2(e.target.value)} className="form-control mb-2" placeholder="Option B" />
                        <input value={option3} onChange={(e) => setOption3(e.target.value)} className="form-control mb-2" placeholder="Option C" />
                        <input value={option4} onChange={(e) => setOption4(e.target.value)} className="form-control mb-2" placeholder="Option D" />
                        <input value={year} onChange={(e) => setYear(e.target.value)} className="form-control mb-2" placeholder="Year (Optional)" />
                        <select className="form-select mb-2" value={correctOption} onChange={(e) => setCorrectOption(e.target.value)}>
                            <option value="null">Select Option</option>
                            <option value="a">A</option>
                            <option value="b">B</option>
                            <option value="c">C</option>
                            <option value="d">D</option>
                        </select>
                        <button onClick={addQuestion} className="btn btn-primary w-100">Save</button>
                    </div>
                ) : (
                    <div className="card p-3">
                        <p className="fs-5 text-center">Edit Question</p>
                        <input value={questionName} onChange={(e) => setQuestionName(e.target.value)} className="form-control mb-2" placeholder="Question Name" />
                        <input value={option1} onChange={(e) => setOption1(e.target.value)} className="form-control mb-2" placeholder="Option A" />
                        <input value={option2} onChange={(e) => setOption2(e.target.value)} className="form-control mb-2" placeholder="Option B" />
                        <input value={option3} onChange={(e) => setOption3(e.target.value)} className="form-control mb-2" placeholder="Option C" />
                        <input value={option4} onChange={(e) => setOption4(e.target.value)} className="form-control mb-2" placeholder="Option D" />
                        <input value={year} onChange={(e) => setYear(e.target.value)} className="form-control mb-2" placeholder="Year (Optional)" />
                        <select className="form-select mb-2" value={correctOption} onChange={(e) => setCorrectOption(e.target.value)}>
                            <option value="null">Select Option</option>
                            <option value="a">A</option>
                            <option value="b">B</option>
                            <option value="c">C</option>
                            <option value="d">D</option>
                        </select>
                        <p>Reslect the option again otherwise it would not be saved.</p>
                        <button onClick={editQuestion} className="btn btn-primary w-100 mb-2">Save</button>
                        <button onClick={() => {
                            setOption1("");
                            setOption2("");
                            setQuestionName("");
                            setOption3("");
                            setOption4("");
                            setCorrectOption("");
                            setAddForm(true);
                            setYear()
                        }} className="btn btn-danger w-100">Close</button>
                    </div>
                )}

                <div className='mt-3'>
                    <div className="table-responsive rounded rounded-3">
                        <table className='table table-striped table-bordered'>
                            <thead>
                                <tr>
                                    <th>Question Name</th>
                                    <th>Option A</th>
                                    <th>Option B</th>
                                    <th>Option C</th>
                                    <th>Option D</th>
                                    <th>Correct</th>
                                    <th className="text-end">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {questionList.map((question, index) => (
                                    <tr key={index}>
                                        <td>{question.question}</td>
                                        <td>{question.a}</td>
                                        <td>{question.b}</td>
                                        <td>{question.c}</td>
                                        <td>{question.d}</td>
                                        <td>{question.correct}</td>
                                        <td className='m-0 p-2' style={{ width: "20%" }}>
                                            <div className='d-flex justify-content-end gap-2'>
                                                <button className='btn btn-warning' onClick={() => {
                                                    setQuestionId(question.id);
                                                    setQuestionName(question.question);
                                                    setOption1(question.a); // Set the options if you have them in the question object
                                                    setOption2(question.b);
                                                    setOption3(question.c);
                                                    setOption4(question.d);
                                                    setCorrectOption(question.correct)
                                                    setAddForm(false);
                                                }}>Edit</button>
                                                <button onClick={() => deleteQuestion(question.id)} className='btn btn-danger'>Delete</button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}
