import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { useNavigate } from 'react-router-dom';
import Header from "./Header.jsx";
import {API_URL, Loader} from "../App.js";
import { useParams } from 'react-router-dom';

export default function Streams() {
    const { id } = useParams();

    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    function move(id) {
        navigate(`/subjects/${id}`)
    }

    useEffect(() => {
        setIsLoading(true)
        const data = new FormData();
        data.append("action", "fetch_streams_by_board");
        data.append("boardID", id);
        fetch(API_URL + '/admin/Categories.php', {
            method: "POST",
            body: data
        })
            .then(function(res) { return res.json() })
            .then(function(data) {
                if(data.status == true) {
                 setCategories(data.data);
                } else {
                    setCategories([]);
                }
                setIsLoading(false)
            })
    }, []);

    return (
        <div>
            <Header/>
            <Loader loading={isLoading}/>
            <div className="container">
            <h5 className="text-center mb-3">Your Stream</h5>
                {categories.length > 0 ? (
                <div className="d-flex flex-wrap" style={{justifyItems: 'space-between', gap: '10px'}}>
                    {categories.map((category) => (
                        <div onClick={() => move(category.id)} key={category.id}
                             className="d-flex gap-2 card mb-1 rounded-5 p-3 justify-content-center align-items-center"
                             style={{width: "48.5%"}}>
                            <img src={category.image} alt="image"
                            style={{maxHeight: "100px"}}
                            />
                            <p className="fs-5 mb-0">{category.name}</p>
                        </div>
                    ))}
                </div>
                    ) : (
                    <p className="text-center mt-3">No categories found</p>
                )}
            </div>
        </div>
    )
}
