import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import {useNavigate} from "react-router-dom";
import IsLogin from "./IsLogin";

export default function Header() {
    const navigate = useNavigate();
    return (
        <nav className="navbar d-flex flex-row card navbar-light bg-light px-4 mb-3">
            <IsLogin />
            <a className="navbar-brand" href="#">
                <img src="https://mcqkaro.com/wp-content/uploads/2023/09/Untitled-design-8.png" alt="MCQKaro Logo" style={{ height: '40px', width: 'auto' }} />
                </a>            <div className="gap-4 d-none d-sm-block">
                <a onClick={() => {window.location.href = "https://mcqkaro.com"}} className="navbar-brand" href="#">Home</a>
                <a onClick={() => {window.location.href = "https://mcqkaro.com/blog"}} className="navbar-brand" href="#">Blogs</a>
                <a onClick={() => {window.location.href = "https://mcqkaro.com/contact/"}} className="navbar-brand" href="#">Contact Support</a>
                <a onClick={() => {
                    localStorage.clear();
                    window.location.reload();
                }} className="navbar-brand" href="#">Logout</a>
            </div>
        </nav>
    )
}
